/**
 * Agreement Data Model (Agreement)
 * */
export class Agreement implements IAgreement {
    AgreementType: string;
    StartDate: string;
    AgreementId: number;
    AgreementClassificationId: number;
    Promotions: Promotion[];
    IsActive: boolean;
    AgreementGroups: AgreementGroup[];
    Items: any[];
    Bundles: Bundle[];
    Name: string;

    constructor() {
    }
}

/**
 * IAgreement Data Model (IAgreement)
 * */
export interface IAgreement {
    AgreementType: string;
    StartDate: string;
    AgreementId: number;
    AgreementClassificationId: number;
    Promotions: Promotion[];
    IsActive: boolean;
    AgreementGroups: AgreementGroup[];
    Items: any[];
    Bundles: Bundle[];
    Name: string;
}

/**
 * Promotion data model
 */
export interface Promotion {
    Effects: Effect[];
    PromotionId: number;
    AgreementTerms: AgreementTerm[];
    Name: string;
    AllowRecurring: boolean;
}

/**
 * Effect data model
 */
export interface Effect {
    ItemCategory: string;
    EffectAmount: number;
    FeeType: string;
    EffectStart: number;
    ItemType: string;
    AppliesTo: string;
    Sequence: number;
    EffectType: string;
    EffectCount: number;
}

/**
 * AgreementGroup data model
 */
export interface AgreementGroup {
    Id: number;
    Name: string;
}

/**
 * AgreementTerm data model
 */
export interface AgreementTerm {
    AgreementTerms: string;
}

/**
 * Bundle data model
 */
export interface Bundle {
    AgreementItemId: number;
    StartDate: Date;
    BundleId: number;
    Description: string;
    BundleType: string;
    IsActive: boolean;
    Items: ItemA[];
    MemberRequired: boolean;
    Code: string;
    NewAgreements: boolean;
    Name: string;
}

/**
 * Item data model
 */
export interface ItemA {
    QualifiedPrice: string;
    Description: string;
    Category: string;
    FeeType: string;
    ItemType: string;
    AllowPIF: boolean;
    UPC: string;
    IsKeyItem: boolean;
    MemberRequired: boolean;
    ItemId: number;
    MinPrice: string;
    Name: string;
    PerpetualOptions: PerpetualOption[];
    Price: string;
    Qty: number;
    MaxPrice: string;
}

/**
 * PerpetualOption data model
 */
export interface PerpetualOption {
    StartDate: Date;
    InitialQuantity: number;
    ItemTermsId: number;
    Terms: Term[];
    PaymentOptionId: number;
    InitialPrice: string;
    MaxDownAmount: number;
    MinPayInstallments: number;
    Name: string;
    ObligatedQuantity: number;
    MaxPayInstallments: number;
}

/**
 * Term data model
 */
export interface Term {
    Quantity: number;
    Id: number;
    Sequence: number;
    Price: string;
    BillingSchedule: {id: number; Name: string};
    PaymentSchedule: {id: number; Name: string};
}













