import {AbstractControl} from '@angular/forms';
export class EmailValidation {

    static MatchEmail(AC: AbstractControl) {
       const email = AC.get('email').value;
       const confirmEmail = AC.get('confirmEmail').value;

        if (email !== confirmEmail) {
            AC.get('confirmEmail').setErrors( {MatchEmail: true} );
        } else {
            return null;
        }
    }
}
